import { IS_SERVER } from './ypp'

// @ts-check

export const LOCK_BODY_KEY = 'data-scroll-before-lock'
// 用于 el.dataset[LOCK_BODY_DATA_KEY]
// export const LOCK_BODY_DATA_KEY = 'scrollBeforeLock'

const INTERNAL_LOCK_KEY = 'data-lock-scroll'

/**
 * 锁定屏幕，禁止滚动（适用于移动端）
 * @returns {() => void} 返回去除锁定的方法
 */
export function lockScroll() {
  const noop = () => {}
  if (IS_SERVER) return noop
  const htmlStyle = document.documentElement.style
  const bodyStyle = document.body.style
  const scrollY = window.scrollY

  if (hasLockScroll()) {
    console.warn(`屏幕已经锁定了，不能重复锁定`)
    return noop
  }

  const height = htmlStyle.height
  const pos = bodyStyle.position
  const top = bodyStyle.top
  const left = bodyStyle.left
  const right = bodyStyle.right

  bodyStyle.position = 'fixed'
  bodyStyle.top = -scrollY + 'px'
  bodyStyle.left = '0'
  bodyStyle.right = '0'
  htmlStyle.height = '100%'
  document.body.setAttribute(LOCK_BODY_KEY, scrollY + '')
  document.body.setAttribute(
    INTERNAL_LOCK_KEY,
    JSON.stringify({
      height,
      pos,
      top,
      left,
      right,
      scrollY,
    })
  )

  return unlockScroll
}

export function unlockScroll() {
  if (IS_SERVER) return
  const str = document.body.getAttribute(INTERNAL_LOCK_KEY)
  if (!str) return

  try {
    const { height, pos, top, left, right, scrollY } = JSON.parse(str)
    document.documentElement.style.height = height

    const bodyStyle = document.body.style
    bodyStyle.position = pos
    bodyStyle.top = top
    bodyStyle.left = left
    bodyStyle.right = right
    window.scrollTo(0, scrollY)
    setTimeout(() => {
      document.body.removeAttribute(LOCK_BODY_KEY)
      document.body.removeAttribute(INTERNAL_LOCK_KEY)
    }, 30)
  } catch (e) {}
}

export function hasLockScroll() {
  return !!document.body.getAttribute(LOCK_BODY_KEY)
}
