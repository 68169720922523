export const OUT_SIDE_CURRENT_XUA = "OUT_SIDE_CURRENT_XUA";

export const TRIGGER_SM_REGISTER = "TRIGGER_SM_REGISTER";

export const TRIGGER_SM_LOGIN = "TRIGGER_SM_LOGIN";

export const OUT_SIDE_CURRENT_TOKEN = "OUT_SIDE_CURRENT_TOKEN";

export const OUT_SIDE_CURRENT_UDID = "OUT_SIDE_CURRENT_UDID";

export const APPLICATION_TYPE_ENUM = {
  both: "both",
  onlyLogin: "onlyLogin",
  loginRegister: "loginRegister",
};

export const STEP_ENUM = {
  phone: 1,
  code: 2,
  register: 3,
};
