<template>
  <div>
    <div class="login-tab-box">
      <div
        v-for="v in tabList"
        :key="v.value"
        class="login-tab"
        :class="tab === v.value && 'login-tab-act'"
        @click="tab = v.value"
      >
        {{ v.label }}
      </div>
    </div>
    <InputPhonePc
      :tab="tab"
      :applicationType="applicationType"
      :sm="sm"
      @login="handleLogin"
    />
  </div>
</template>

<script>
import "../app.scss";
import InputPhonePc from "./inputPhonePc.vue";
import InputRegister from "./inputRegister.vue";
import InputCode from "./inputCode.vue";

const tabList = [
  {
    value: 0,
    label: "账号登录",
  },
  {
    value: 1,
    label: "短信登录",
  },
];

export default {
  components: {
    InputPhonePc,
    InputRegister,
    InputCode,
  },
  props: {
    sm: Object,
    applicationType: String,
  },
  data() {
    return {
      tabList,
      tab: 0,
    };
  },
  methods: {
    handleLogin(params) {
      this.$emit("login", params);
    },
  },
  computed: {},
};
</script>
