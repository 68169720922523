// import V from 'vconsole'
import Cookies from "js-cookie";
export const DemoTestMixin = {
  data() {
    return {
      show:
        this.$bridge.isAndroid || this.$bridge.isIOS || this.$bridge.isInYpp,
    };
  },
  computed: {
    title() {
      return this.$route.path.replace(/^\/(demo|test)\/?/, "") || __PROJECT__;
    },
  },
  mounted() {
    /* eslint-disable */
    if (!this.show) return;
  },
  beforeDestroy() {
    /* eslint-disable */
    if (!this.show) return;
  },
};

/**
 * leading: true  (开始是否执行)
 * trailing: true  (末尾是否执行)
 */
export function throttle(wait, fn) {
  let lastCall = 0;
  let sid;
  const clearSid = () => {
    if (sid) {
      clearTimeout(sid);
      sid = null;
    }
  };

  return (...args) => {
    const run = () => {
      fn(...args);
      lastCall = Date.now();
    };

    clearSid();

    if (Date.now() - lastCall >= wait) {
      run();
    } else {
      // 保证 trailing 一定执行
      sid = setTimeout(run, wait);
    }
  };
}

export const px2Vw = (px) => {
  return `${(px * 100) / 750}vw`;
};
// 将750设计稿宽度下的px值 转化为 当前视口下的px值
export const pxBase750ToPxBaseViewPort = (val) => {
  const clientWidth = window.innerWidth || screen.availWidth;
  return (val * clientWidth) / 750;
};
export const setImg = (height, width, url) => {
  const imgStyle = {};
  if (height) {
    imgStyle.height = px2Vw(height);
  }
  if (width) {
    imgStyle.width = px2Vw(width);
  }
  if (url) {
    imgStyle.background =
      url.startsWith("#") || url.startsWith("rgb")
        ? url
        : `url('${url}') no-repeat`;
    imgStyle.backgroundSize = "100% !important";
  }
  return imgStyle;
};
export const setFont = (size, color, align, family) => {
  const fontStyle = {};
  if (size) {
    fontStyle.fontSize = px2Vw(size);
  }
  if (color) {
    fontStyle.color = color;
  }
  if (align) {
    fontStyle.textAlign = align;
  }
  if (family) {
    fontStyle.fontFamily = family;
  }
  return fontStyle;
};
export const setPosition = (type, left, top, bottom, right) => {
  const style = {};
  if (type === "absolute") {
    style.position = "absolute";
    if (left) {
      style.left = px2Vw(left);
    }
    if (top) {
      style.top = px2Vw(top);
    } else if (bottom) {
      style.top = "unset";
      style.bottom = px2Vw(bottom);
    } else if (right) {
      style.right = px2Vw(right);
    }
  }
  if (type === "normal") {
    if (left) {
      style.marginLeft = px2Vw(left);
    }
    if (top) {
      style.marginTop = px2Vw(top);
    }
    if (bottom) {
      style.marginBottom = px2Vw(bottom);
    }
  }
  if (type === "padding") {
    if (left) {
      style.paddingLeft = px2Vw(left);
    }
    if (top) {
      style.paddingTop = px2Vw(top);
    }
    if (bottom) {
      style.paddingBottom = px2Vw(bottom);
    }
  }
  return style;
};

const domain = location.port
  ? document.domain
  : document.domain.split(".").slice(-2).join(".");

export const setCookiesAndStorage = (key, storageVal, cookiesVal) => {
  localStorage.setItem(key, storageVal);
  Cookies.set(key, cookiesVal, {
    domain,
    expires: 7,
  });
};

export const realStrLength = (str) => {
  let realLength = 0;
  const len = str.length;
  let charCode = -1;
  let maxStr = "";
  for (let i = 0; i < len; i++) {
    charCode = str.charCodeAt(i);
    const cha = charCode >= 0 && charCode <= 128 ? 1 : 2;
    realLength += cha;
    if (realLength === 20) {
      maxStr += str[i];
      break;
    }
    if (realLength > 20) {
      break;
    }
    maxStr += str[i];
  }
  return { realLength: realLength, maxStr };
};

export const rainbowReport = ({ eventType, uid }) => {
  if (window.rainbow) {
    window.rainbow.report({ eventType, uid });
  }
};

/**
 * 校验手机号
 * @param {*} phoneNumber 
 * @returns 
 */
export const phoneIsValid = (phoneNumber) => {
  let isValid = false;
  if (/^[1][2-9][0-9]{9}$/.test(phoneNumber)) {
    isValid = true;
  }
  return isValid;
};

/**
 * 格式化手机号
 * @param {*} phoneNumber 
 * @returns 
 */
export const formatPhoneNumber = (phoneNumber) => {
  const matches = /^(\d{3})(\d{4})(\d{4})$/.exec(phoneNumber);
  if (matches) {
    return matches[1] + " " + matches[2] + " " + matches[3];
  }
}