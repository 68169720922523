<template>
  <div class="count-down-wrap">
    <slot :days="days" :hours="hours" :minutes="minutes" :seconds="seconds" :currentLeftTime="currentLeftTime">
      <span class="count-down-content">倒计时: {{days}}天{{hours}}小时{{minutes}}分{{seconds}}秒</span>
    </slot>
  </div>
</template>

<script>
import { MILLISECONDS, MINUTE, HOUR, DAY, DELAY_TIME } from './config'
import { IS_SERVER } from '../../common/ypp'

export default {
  name: 'count-down',
  props: {
    //__PROPS_START
    /**
     * 是否自动开始倒计时
     */
    autoStart: {
      type: Boolean,
      default: true,
    },
    /**
    * 剩余时间，单位毫秒
    */
    leftTime: {
      type: Number,
      default: 0,
    },
    //__PROPS_END
  },
  data() {
    return {
      currentLeftTime: this.leftTime,
      startTime: undefined,
      requestId: undefined,
      setTimeoutId: undefined,
    }
  },
  watch: {
    $props: {
      deep: true,
      immediate: true,
      handler() {
        this.start()
      },
    },
  },
  methods: {
    requestAnimationCallBack() {
      const currentTime = new Date().getTime()
      if ((currentTime - this.startTime) < DELAY_TIME) {
        this.requestId = requestAnimationFrame(this.requestAnimationCallBack)
      } else {
        this.countDown()
      }
    },
    countDown() {
      const currentTime = new Date().getTime()
      this.currentLeftTime -= currentTime - this.startTime
      this.startTime = currentTime
      this.$emit('time-change', this.currentLeftTime)
      this.continue()
    },
    continue() {
      if (this.currentLeftTime < MILLISECONDS && this.currentLeftTime > 0) {
        setTimeout(() => {
          this.currentLeftTime = 0
          this.stop()
        }, this.currentLeftTime)
        return
      } else if (this.currentLeftTime <= 0) {
        this.currentLeftTime = 0
        this.stop()
        return
      }

      if (!IS_SERVER && window.requestAnimationFrame) {
        this.requestId = requestAnimationFrame(this.requestAnimationCallBack)
      } else {
        this.setTimeoutId = setTimeout(() => this.countDown(), DELAY_TIME)
      }
    },
    start() {
      this.clean()
      this.currentLeftTime = this.leftTime
      if (!this.autoStart) {
        return
      }
      this.startTime = new Date().getTime()
      this.continue()
    },
    stop() {
      this.clean()
      this.$emit('onFinish')
    },
    clean() {
      this.startTime = undefined
      if (this.requestId) {
        cancelAnimationFrame(this.requestId)
      }
      if (this.setTimeoutId) {
        clearTimeout(this.setTimeoutId)
      }
    },
  },
  computed: {
    days() {
      return Math.floor(this.currentLeftTime / DAY)
    },
    hours() {
      return Math.floor((this.currentLeftTime % DAY) / HOUR)
    },
    minutes() {
      return Math.floor((this.currentLeftTime % HOUR) / MINUTE)
    },
    seconds() {
      return Math.floor((this.currentLeftTime % MINUTE) / MILLISECONDS)
    },
  },
  mounted() {
    this.start()
  },
  beforeDestroy() {
    this.clean()
  },
}
</script>
