import AppModal from './app.vue'
import { lockScroll, unlockScroll } from './common/lockScroll'
import Vue from 'vue'
import Cookies from 'js-cookie'
import { sm } from '@fe/sm-verify-vue'
import {
  OUT_SIDE_CURRENT_UDID,
  OUT_SIDE_CURRENT_TOKEN,
  OUT_SIDE_CURRENT_XUA,
} from './common/constants/index'
import './app.scss'
let msgQueue = {}

const APP = Vue.extend(AppModal)

let isRegister = false

APP.defaultOptions = {
  lockScroll: true,
  showMask: true,
  getIsRegister: register => {
    isRegister = register
  },
}

APP.prototype.close = function () {
  this.visible = false
  unlockScroll()
}

// APP实例
let _instance = null

const defaultCallback = action => {
  if (msgQueue) {
    if (msgQueue.resolve) {
      if (action === 'confirm') {
        msgQueue.resolve({
          isRegister,
        })
      } else {
        msgQueue.reject()
      }
    }
  }
}

const lifecycle = {
  run(options) {
    return this.getInstance(options)
      .then(instance => this.created(instance, options))
      .then(instance => this.mounted(instance, options))
  },
  // 创建实例
  getInstance(options) {
    if (!_instance) {
      _instance = new APP({
        el: document.createElement('div'),
      })

      // 挂载
      const root = options.root || document.body
      root.appendChild(_instance.$el)
      _instance.callback = defaultCallback
    }
    // 清除
    // _instance.$el.removeEventListener('transitionend', _instance.onClose)

    return Promise.resolve(_instance)
  },
  // 实例属性
  created(instance, options) {
    options = Object.assign({}, APP.defaultOptions, options)
    Object.keys(options).forEach(key => {
      instance[key] = options[key]
    })

    // 回调
    // if (options.onClose) {
    // _instance.$el.addEventListener('transitionend', options.onClose)
    // }
    return instance
  },
  // 显示
  mounted(instance, options) {
    options = Object.assign({}, APP.defaultOptions, options)
    Object.keys(options).forEach(key => {
      instance[key] = options[key]
    })
    Vue.nextTick(function () {
      instance.visible = true
      if (options.lockScroll && options.showMask) {
        lockScroll()
      }
    })
  },
}

function show(cfg = {}) {
  return lifecycle.run(cfg)
}

function retry(times = 0, delay = 500) {
  return new Promise(function (resolve, reject) {
    var attempt = function () {
      sm.getUser({
        'X-AccessToken': Cookies.get(OUT_SIDE_CURRENT_TOKEN),
        'X-Udid': Cookies.get(OUT_SIDE_CURRENT_UDID),
        'x-user-agent': Cookies.get(OUT_SIDE_CURRENT_XUA),
      })
        .then(resolve)
        .catch(function (err) {
          console.log(`Attempt #${times} failed`)
          if (!!times) {
            times--
            setTimeout(function () {
              attempt()
            }, delay)
          } else {
            reject(err)
          }
        })
    }
    // APP内不需要获取用户信息 默认有
    if (YPP.app.isYpp) {
      resolve({})
    } else {
      attempt()
    }
  })
}

export default {
  show(options) {
    if (typeof Promise !== 'undefined') {
      return new Promise((resolve, reject) => {
        msgQueue = {
          resolve: resolve,
          reject: reject,
        }
        show(options)
      })
    } else {
      show(options)
    }
  },
  close() {
    _instance && _instance.close()
  },
  isLogin(options = {}) {
    options.appName && sm.init(options.appName)
    return retry(options && options.isCheck ? 3 : 0)
  },
  logout(appName) {
    const accessToken = Cookies.get(OUT_SIDE_CURRENT_TOKEN)

    if (!appName) {
      // 兼容未修改的项目
      return sm
        .exitLogin(accessToken)
        .then(() => {
          Cookies.remove(OUT_SIDE_CURRENT_TOKEN)
          window.location.reload()
          resolve()
        })
        .catch(e => {
          reject(e)
        })
    } else {
      // 修复reload后，登出header不带 x-user-agent 问题
      return new Promise((resolve, reject) => {
        appName && sm.init(appName)
        sm.getXUD(xud => {
          // todo 这里是异步加载完成之后调用的
          sm.exitLogin(accessToken)
            .then(() => {
              Cookies.remove(OUT_SIDE_CURRENT_TOKEN)
              window.location.reload()
              resolve()
            })
            .catch(e => {
              reject(e)
            })
        })
      })
    }
  },
}
