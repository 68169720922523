<template>
  <div>
    <transition name="mask-pop" v-if="showMask">
      <div class="sarot-toast-mask" v-show="visible"
        :style="{
          backgroundColor: maskColor,
        }"></div>
    </transition>

    <transition name="toast-pop">
      <div class="sarot-toast-container" v-show="visible">
        <div :class="['sarot-toast', `sarot-place-${position}`, className, (hasIcon || type === 'loading') && 'sarot-toast-with-icon', type === 'loading' && 'toast-loading']" ref="toast">
          <div v-if="hasIcon" :class="['sarot-toast-icon', customHtml && 'sarot-toast-icon-auto']">
            <i v-if="type === 'success'" class="iconfont icon-lux_iconfont_toast_successful"></i>
            <i v-if="type === 'error'" class="iconfont icon-lux_iconfont_toast_failure"></i>
            {{content}}
            <div :class="['sarot-toast-html']" v-if="!content" v-html="customHtml"></div>
          </div>
          <div v-if="!hasIcon" >
            <span :class="['sarot-toast-body', type === 'loading' && 'sarot-toast-loading-body']">
              {{ content }}
            </span>
            <div :class="['sarot-toast-html']" v-if="!content" v-html="customHtml"></div>
          </div>
<!--          <span v-if="!hasIcon" :class="['toast-body', type === 'loading' && 'toast-loading-body']">-->
<!--            {{ content }}-->
<!--          </span>-->
        </div>
      </div>
    </transition>
  </div>

</template>
<script>

export default {
  name: 'toast',
  props: {
    //__PROPS_START
    content: String,
    /** 可选值： success、error、text、loading */
    type: {
      type: String,
      default: 'text',
    },
    duration: Number,
    /** 可选值：top、middle、bottom */
    position: String,
    /** word-break-all */
    className: String,
    onClose: Function,
    /** 是否开启这招，默认不开启 */
    showMask: Boolean,
    /** 遮照颜色 */
    maskColor: String,
    /** 自定义内容 */
    customHtml: String,
    //__PROPS_END
  },
  components: {
  },
  computed: {
    hasIcon() {
      return ['success', 'error'].includes(this.type)
    },
  },
  data() {
    return {
      visible: false,
      multiLine: false,
    }
  },
}
</script>
