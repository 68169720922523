<template>
  <div class="register">
    <div class="contentBox">
      <h3 class="title" :style="titleStyle">完善信息</h3>
      <div class="inputBox">
        <span class="areaCode">昵称</span>
        <div class="tipText" v-if="!nickname">最多10个汉字或20个字符</div>
        <input
          @input="onNicknameChange"
          type="text"
          v-model="nickname"
          maxlength="20"
          class="phoneNumberBox"
        />
      </div>
      <div class="inputBox2">
        <span class="areaCode">性别</span>
        <img
          :key="i"
          v-for="(v, i) in 2"
          @click="selectGender(i)"
          class="genderImg"
          :src="getCurGenderImg(i)"
        />
      </div>
      <div class="registerTipText">*注册成功后性别不可修改</div>
    </div>
    <div
      class="commitBtn"
      :class="isCanSubmit ? 'registerBtn' : 'registerGeryBtn'"
      @click="handleSubmit"
    ></div>
  </div>
</template>

<script>
import "./inputRegister.scss";
import { setFont, realStrLength } from "../common/utils";

export default {
  components: {},
  props: {
    titleFontSize: {
      type: Number,
      default: 48,
    },
    titleFontColor: String,
    boyImg: {
      type: String,
      default:
        "https://p6.hellobixin.com/bx-user/70057973d5794f88b0af59715b14a4bb.png",
    },
    boyGeryImg: {
      type: String,
      default:
        "https://p6.hellobixin.com/bx-user/0c5ec42f851d49ecb2d1a1604a5e15de.png",
    },
    girlImg: {
      type: String,
      default:
        "https://p6.hellobixin.com/bx-user/597f6c4b91f64f64b6921ad23b7837cd.png",
    },
    girlGeryImg: {
      type: String,
      default:
        "https://p6.hellobixin.com/bx-user/3ad9e8d3e031434a9d9b8032b3d50842.png",
    },
    sm: Object,
  },
  data() {
    return {
      nickname: "",
      gender: 0,
    };
  },
  methods: {
    onNicknameChange() {
      if (/^[A-Za-z0-9\u4e00-\u9fa5]+$/.test(this.nickname)) {
        const { maxStr } = realStrLength(this.nickname);
        this.nickname = maxStr;
      } else {
        this.nickname = this.nickname.substring(0, this.nickname.length - 1);
      }
    },
    selectGender(gender) {
      this.gender = gender;
    },
    handleSubmit() {
      if (!this.isCanSubmit) return;
      const { nickname, gender } = this;
      this.$emit("register", { nickname, gender });
    },
    getCurGenderImg(gender) {
      const { girlImg, girlGeryImg, boyImg, boyGeryImg } = this;
      if (gender) {
        return gender === this.gender ? girlImg : girlGeryImg;
      } else {
        return gender === this.gender ? boyImg : boyGeryImg;
      }
    },
  },
  computed: {
    isCanSubmit() {
      return this.nickname !== "" && this.gender !== "";
    },
    titleStyle() {
      return setFont(this.titleFontSize, this.titleFontColor);
    },
  },
  mounted() {},
};
</script>
