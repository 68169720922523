import Vue from 'vue'
import OriginalToast from './toast.vue'
import { IS_SERVER } from '../../common/ypp'

import './toast.scss'

const Toast = Vue.extend(OriginalToast)
Toast.defaultOptions = {
  duration: 2000,
  position: 'middle',
}
Toast.prototype.close = function() {
  this.visible = false
}

// Toast实例
let _instance = null

// 生命周期函数集
const lifecycle = {
  run(options) {
    if (IS_SERVER) return

    this.getInstance(options)
      .then(instance => this.created(instance, options))
      .then(instance => this.mounted(instance, options))
  },
  // 创建实例
  getInstance(options) {
    if (!_instance) {
      _instance = new Toast({
        el: document.createElement('div'),
      })

      // 挂载
      const root = options.root || document.body
      root.appendChild(_instance.$el)
    }

    // 清除
    _instance.$el.removeEventListener('transitionend', _instance.onClose)
    clearTimeout(_instance.timer)

    return Promise.resolve(_instance)
  },
  // 实例属性
  created(instance, options) {
    options = Object.assign({}, Toast.defaultOptions, options)
    Object.keys(options).forEach(key => {
      instance[key] = options[key]
    })

    // 回调
    if (options.onClose) {
      _instance.$el.addEventListener('transitionend', options.onClose)
    }

    return instance
  },
  // 显示
  mounted(instance, options) {
    Vue.nextTick(function() {
      instance.visible = true

      if (options.type !== 'loading' && instance.duration > 0) {
        instance.timer = setTimeout(() => {
          instance.close()
        }, instance.duration)
      }
    })
  },
}

function show(options, type) {
  if (typeof options === 'string') {
    options = { type: 'text', content: options }
  } else {
    options = Object.assign({type: 'text'}, options)
  }
  if (type) options.type = type

  lifecycle.run(options)
}

export default {
  show(options) {
    show(options)
  },
  showLoading(options) {
    show(options, 'loading')
  },
  showSuccess(options) {
    show(options, 'success')
  },
  showError(options) {
    show(options, 'error')
  },
  close() {
    _instance && _instance.close()
  },
}
