<template>
  <div class="inputCode">
    <div class="contentBox">
      <h3 class="title">输入验证码</h3>
      <p class="ptext">验证码已发送至{{ formatPhoneNumber }}</p>
      <div class="inputBox" @click="setInputFocus">
        <div
          v-for="(v, i) in 4"
          :key="i"
          :class="phoneCode.length === i ? 'codeBoxCurrent' : 'codeBox'"
        >
          {{ showCode(i) }}
          <div v-if="isFocus" :style="cursorStyle" class="cursorLine" />
        </div>
        <input
          @blur="onInputBlur"
          @input="onFinishInputCode"
          ref="codeInput"
          class="input"
          type="tel"
          v-model="phoneCode"
          maxlength="4"
        />
      </div>
      <div class="ruleBox">
        <CountDown
          :leftTime="tipTextType === 1 ? 60000 : 0"
          @onFinish="resetCode"
        >
          <div class="desc" slot-scope="props" @click="handleSendCode">
            {{ getTipsText(props.seconds) }}
          </div>
        </CountDown>
      </div>
    </div>
  </div>
</template>

<script>
import "./inputCode.scss";
import Toast from "./toast/index";
import CountDown from "./count-down/count-down.vue";
import { setPosition, formatPhoneNumber } from "../common/utils";

export default {
  components: {
    CountDown,
  },
  props: {
    phoneNumber: String,
    sm: Object,
  },
  data() {
    return {
      phoneCode: "",
      tipTextType: 1,
      isFocus: false,
    };
  },
  watch: {},
  methods: {
    showCode(index) {
      const arr = this.phoneCode.split("");
      return arr[index] ? arr[index] : "";
    },
    onInputBlur() {
      this.isFocus = false;
    },
    onStartInput() {
      const length = this.phoneCode.split("").length;
      this.$refs.codeInput.selectionStart = length;
    },
    setInputFocus() {
      this.$refs.codeInput.focus();
      this.onStartInput();
      this.isFocus = true;
    },
    setInputBlur() {
      this.$refs.codeInput.blur();
      this.isFocus = false;
    },
    
    // 重新获取验证码
    resetCode() {
      this.phoneCode = "";
      this.tipTextType = 2;
    },
    async handleSendCode() {
      if (this.tipTextType !== 2) return;
      if (this.isLock) {
        Toast.show("正在发送，请稍等");
        return;
      }
      this.tipTextType = 1;
      this.isLock = true;
      try {
        await this.sm.sendCode(this.phoneNumber, "86");
        this.$emit("sendCode", this.phoneNumber);
      } catch (err) {
        Toast.show(err.msg);
      } finally {
        this.isLock = false;
        // this.setInputBlur()
      }
      this.sm.smOnClose(() => {
        this.isLock = false;
      });
    },
    onFinishInputCode() {
      if (this.phoneCode.length === 4) {
        this.setInputBlur()
        this.$emit("confirmLogin", this.phoneCode);
      }
    },
    getTipsText(seconds) {
      return `${seconds === 0 ? "" : seconds}${
        this.tipTextType === 1 ? "秒后重新获取验证码" : " 重新获取短信验证码"
      }`;
    },
  },
  computed: {
    cursorStyle() {
      const style = {
        ...setPosition("absolute", "", "", ""),
      };
      return style;
    },
    formatPhoneNumber() {
      return formatPhoneNumber(this.phoneNumber);
    },
  },
  mounted() {
    console.log('66611116')
  },
};
</script>
