<template>
  <div class="login-input-box">
    <div class="inputPhone">
      <div class="contentBox">
        <div class="inputBox pc-inputBox">
          <input
            type="tel"
            v-model="phoneNumber"
            maxlength="11"
            class="phoneNumberBox pc-phoneNumberBox"
            placeholder="请输入手机号码"
          />
        </div>
        <div v-if="!tab" :class="['inputBox', 'pc-inputBox']">
          <input
            type="password"
            v-model="passNumber"
            style="margin-top: 16px"
            :class="['phoneNumberBox', 'pc-phoneNumberBox']"
            placeholder="请输入密码"
          />
        </div>
        <div v-else class="inputBox pc-inputBox">
          <input
            type="tel"
            v-model="codeNumber"
            maxlength="4"
            class="codeNumberBox pc-codeNumberBox"
            placeholder="请输入验证码"
          />
          <div v-if="status === 1" :class="sendCodeTextClass" @click="sendCode">
            获取验证码
          </div>
          <CountDown
            v-else
            :leftTime="status === 2 ? 60000 : 0"
            @onFinish="status = 3"
          >
            <div
              style="width: 160px"
              :class="sendCodeTextClass"
              slot-scope="props"
              @click="sendCode"
            >
              {{ getTipsText(props.seconds) }}
            </div>
          </CountDown>
        </div>
      </div>
    </div>
    <div
      :class="['pc-btn-confirm', 'login-btn', !isCanLogin && 'noCanLogin']"
      @click="handleLogin"
    >
      登录
    </div>
  </div>
</template>

<script>
import "./inputPhone.scss";
import "../app.scss";
import Toast from "./toast/index";
import CountDown from "./count-down/count-down.vue";
import { phoneIsValid } from "../common/utils";
export default {
  components: {
    CountDown,
  },
  props: {
    tab: {
      type: Number,
      default: 0,
    },
    sm: Object,
  },
  data() {
    return {
      phoneNumber: "", // 手机号
      passNumber: "", // 密码
      codeNumber: "", // 验证码
      status: 1, // 1 第一次点击获取验证码  2 60s内不可点击 3 60s可继续获取验证码
    };
  },
  methods: {
    // 获取验证码
    async sendCode() {
      if (this.status === 2) return;
      if (!this.isValid) {
        Toast.show("请输入正确的手机号");
        return;
      }
      if (this.isLock) {
        Toast.show("正在发送，请稍等");
        return;
      }
      this.isLock = true;
      try {
        await this.sm.sendCode(this.phoneNumber, "86");
        this.status = 2;
      } catch (err) {
        Toast.show(err.msg);
      } finally {
        this.isLock = false;
      }
      this.sm.smOnClose(() => {
        this.isLock = false;
      });
    },
    getTipsText(seconds) {
      return `${seconds === 0 ? "" : seconds}${
        this.status === 2 ? "秒后重新获取" : " 重新获取"
      }`;
    },
    handleLogin() {
      if (!this.isCanLogin) return;
      if (this.isLock) return;
      this.isLock = true;
      const { phoneNumber, passNumber, codeNumber } = this;
      const params = { phoneNumber };
      if (this.tab === 0) {
        params.passNumber = passNumber;
      } else {
        params.codeNumber = codeNumber;
      }
      params.cb = () => {
        this.isLock = false;
      };
      this.$emit("login", params);
    },
  },
  computed: {
    isCanLogin() {
      if (this.tab) {
        return this.isValid && this.codeNumber.length === 4;
      } else {
        return this.isValid && this.passNumber !== "";
      }
    },

    isValid() {
      return phoneIsValid(this.phoneNumber);
    },
    sendCodeTextClass() {
      return [
        "pc-codeBtn",
        this.status === 2 || !this.isValid
          ? "disabledBtnClass"
          : "codeBtnClass",
      ];
    },
  },
  mounted() {},
};
</script>
