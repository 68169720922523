<template>
  <div class="login-wrap">
    <div class="login-block-content">
      <!-- 输入手机号 -->
      <InputPhoneMobile
        v-if="step === STEP_ENUM.phone"
        :sm="sm"
        @sendCode="sendCode"
      />
      <!-- 输入验证码 -->
      <InputCode
        :phoneNumber="phoneNumber"
        v-else-if="step === STEP_ENUM.code"
        :sm="sm"
        @confirmLogin="confirmLogin"
      />
      <!-- 注册 -->
      <InputRegister
        v-else-if="step === STEP_ENUM.register"
        :sm="sm"
        @register="handleRegister"
      />
    </div>
  </div>
</template>

<script>
import '../app.scss'
import InputPhoneMobile from './inputPhone.vue'
import InputRegister from './inputRegister.vue'
import InputCode from './inputCode.vue'
import Toast from './toast/index'
import {
  OUT_SIDE_CURRENT_TOKEN,
  TRIGGER_SM_REGISTER,
  TRIGGER_SM_LOGIN,
  STEP_ENUM,
} from '../common/constants/index'
import { setCookiesAndStorage, rainbowReport } from '../common/utils'

export default {
  components: {
    InputPhoneMobile,
    InputRegister,
    InputCode,
  },
  props: {
    sm: Object,
    applicationType: String,
    successToast: Boolean,
    callback: Function,
  },
  data() {
    return {
      step: STEP_ENUM.phone,
      phoneNumber: '',
      STEP_ENUM,
    }
  },
  methods: {
    sendCode(phoneNumber, nationCode) {
      this.nationCode = nationCode
      this.phoneNumber = phoneNumber
      this.step = STEP_ENUM.code
    },
    async confirmLogin(codeNumber) {
      if (this.isLock) {
        return
      }
      this.isLock = true
      const params = {
        codeNumber,
        phoneNumber: this.phoneNumber,
        nationCode: this.nationCode,
        cb: () => {
          this.isLock = false
        },
      }
      this.$emit('login', params)
    },
    //注册
    async handleRegister({ gender, nickname }) {
      const accessToken = localStorage.getItem(OUT_SIDE_CURRENT_TOKEN)
      if (this.isLock) return
      this.isLock = true
      try {
        const res = await this.sm.toRegister(gender, nickname, accessToken)
        this.$parent.getIsRegister(true)
        this.callback && this.callback('confirm', this)
        this.$parent.track(0, res.uidStr)
        Toast.show('注册成功')
        setCookiesAndStorage(TRIGGER_SM_REGISTER, true, true)
        setCookiesAndStorage(TRIGGER_SM_LOGIN, true, true)
        rainbowReport({
          eventType: 'activity_register',
          uid: res.uidStr || res.uid,
        })
        this.callback && this.callback('confirm', this)
        this.$emit('handleClose')
      } finally {
        this.isLock = false
      }
    },
  },
  computed: {},
}
</script>
