<template>
  <div class="inputPhone mobile-input">
    <div class="contentBox">
      <h3 class="title">手机快捷登录</h3>
      <p class="ptext">{{ descText }}</p>
      <div class="inputBox mobile-inputBox">
        <span @click="showCodePopup = true" class="areaCode">+{{currentNationCode}} <img class="arrow-down" src="../common/assets/arrow-down.png" alt=""></span>
        <input
          type="tel"
          v-model="phoneNumber"
          :maxlength="maxlength"
          class="phoneNumberBox mobile-phoneNumberBox"
        />
      </div>
      <div class="ruleBox">
        <img
          class="imgBox"
          @click="isChecked = !isChecked"
          :src="isChecked ? checkedImg : noCheckedImg"
        />
        <span class="agree">同意</span>
        <a @click="goToLink(1)">《用户协议》</a>
        <span>和</span>
        <a @click="goToLink(2)">《隐私政策》</a>
      </div>
    </div>
    <img
      :style="canSubmit ? getCodeBtnStyle : getCodeGeryBtnStyle"
      :src="canSubmit ? codeBtnImg : codeBtnGeryImg"
      class="commitBtn"
      @click="handleSendCode"
    />
    <Popup v-model="showCodePopup" round position="bottom">
      <Picker
        title="选择国家/地区"
        show-toolbar
        :columns="columns"
        :default-index="codeSelectIndex"
        @confirm="handleConfirm"
        @cancel="handleCancel"
      />
    </Popup>
  </div>
</template>

<script>
import "./inputPhone.scss";
import Toast from "./toast/index";
import { setImg, setPosition, phoneIsValid } from "../common/utils";
import Picker from "vant/lib/picker";
import Popup from "vant/lib/popup";
import "vant/lib/picker/style";
import "vant/lib/popup/style";

export default {
  props: {
    privacyUrl: {
      type: String,
      default: "https://h5.bxyuer.com/pandora/313",
    },
    userUrl: {
      type: String,
      default: "https://h5.bxyuer.com/pandora/315",
    },
    descText: {
      type: String,
      default: "未注册的手机号验证后自动创建账号",
    },
    checkedImg: {
      type: String,
      default:
        "https://p6.hellobixin.com/bx-user/c9e4a9ad8dd3415bb9ec54f13255fd7f.png",
    },
    noCheckedImg: {
      type: String,
      default:
        "https://p6.hellobixin.com/bx-user/0aecdad7bfe444f4b097562fe1091614.png",
    },
    codeBtnImg: {
      type: String,
      default:
        "https://p6.hellobixin.com/bx-user/42ac85ee87c64a00a0035ef9a5d5a8ba.png",
    },
    codeBtnGeryImg: {
      type: String,
      default:
        "https://p6.hellobixin.com/bx-user/4bc620136ab3439cbd8bd6c08a45ca4e.png",
    },
    sm: Object,
  },
  components: {
    Picker,
    Popup,
  },
  data() {
    return {
      phoneNumber: "", //手机号
      isChecked: false, //是否勾选同意协议
      columns: [],
      countrySourceList: [],
      codeSelectIndex: 0,
      // 是否展示国家代码选择
      showCodePopup: false,
    };
  },
  watch:{
    currentNationCode(val){
      this.sm.nationCode = val
    }

  },
  methods: {
    goToLink(type) {
      window.open(type === 1 ? this.userUrl : this.privacyUrl);
    },
    handleCancel() {
      this.showCodePopup = false;
    },
    handleConfirm(value, index) {
      this.codeSelectIndex = index;
      this.showCodePopup = false;
    },

    // 获取验证码
    async handleSendCode() {
      if (!this.isChecked) {
        Toast.show("请先勾选同意《用户协议》和《隐私政策》");
        return;
      }
      if (!this.isValid) {
        Toast.show("请输入正确的手机号");
        return;
      }
      if (this.isLock) {
        Toast.show("正在发送，请稍等");
        return;
      }
      this.isLock = true;
      try {
        await this.sm.sendCode(this.phoneNumber, this.currentNationCode);
        this.$emit("sendCode", this.phoneNumber,this.currentNationCode);
      } catch (err) {
        Toast.show(err.msg);
      } finally {
        this.isLock = false;
      }
      this.sm.smOnClose(() => {
        this.isLock = false;
      });
    },
  },
  computed: {
    isValid() {
      const isChinaLand = this.currentNationCode === '86'
      if(!isChinaLand) return true
      return phoneIsValid(this.phoneNumber);
    },
    // 当前国家号码, 不带+
    currentNationCode() {
      if(!this.countrySourceList[this.codeSelectIndex]) return ''
      return this.countrySourceList[this.codeSelectIndex].countryCode.replace(
        "+",
        ""
      );
    },
    maxlength() {
      if (this.currentNationCode === "86") {
        return 11;
      } else {
        return 100;
      }
    },
    getCodeBtnStyle() {
      const style = {
        ...setImg(148, 710),
        ...setPosition("normal", "", 20),
      };
      return style;
    },
    getCodeGeryBtnStyle() {
      const style = {
        ...setImg(148, 710),
        ...setPosition("normal", "", 20),
      };
      return style;
    },
    canSubmit() {
      return this.isValid && this.isChecked;
    },
  },
  mounted() {
    // window.sm = this.sm
    this.sm.nationCodeList().then((res) => {
      this.countrySourceList = [];
      res.countryList.map((item) => {
        this.countrySourceList.push(...item.countries);
      });
      this.columns = this.countrySourceList.map((item) => {
        return `${item.countryName}  ${item.countryCode}`;
      });
    });
  },
};
</script>
