<template>
  <div id="sarot-app" v-show="visible" :class="appClass">
    <!-- 移动端 -->
    <div
      v-if="!isPC"
      class="out-login-modal"
      :class="[lockScroll && 'is-locked', modalClass]"
    >
      <LoginMobile
        ref="loginMobile"
        :sm="sm"
        :applicationType="applicationType"
        :successToast="successToast"
        @handleClose="handleClose"
        :callback="callback"
        @login="handleLogin"
      />
    </div>
    <!-- PC -->
    <div v-else class="pc-login-modal">
      <LoginPc
        :sm="sm"
        :applicationType="applicationType"
        @login="handleLogin"
      />
    </div>
  </div>
</template>

<script>
import './app.scss'
import LoginPc from './components/loginPc.vue'
import LoginMobile from './components/loginMobile.vue'
import Toast from './components/toast/index'
import { sm, dialog } from '@fe/sm-verify-vue'
import {
  OUT_SIDE_CURRENT_XUA,
  OUT_SIDE_CURRENT_TOKEN,
  TRIGGER_SM_REGISTER,
  TRIGGER_SM_LOGIN,
  OUT_SIDE_CURRENT_UDID,
  APPLICATION_TYPE_ENUM,
  STEP_ENUM,
} from './common/constants/index'
import { setCookiesAndStorage, rainbowReport } from './common/utils'

const codeErrMsg = '验证码错误，请重新输入'

export default {
  components: {
    LoginPc,
    LoginMobile,
  },
  props: {
    getIsRegister: {
      type: Function,
    },
    /** 蒙层显示情况下,是否锁屏禁止滚动 */
    lockScroll: {
      type: Boolean,
      default: true,
    },
    /** 是否显示蒙层 */
    showMask: {
      type: Boolean,
      default: true,
    },
    /** 是否是PC端 */
    isPC: {
      type: Boolean,
      default: false,
    },
    /** 登陆成功后自动关闭登陆模块 */
    autoClose: {
      type: Boolean,
      default: true,
    },
    /** 登陆成功后的Toast提示 */
    successToast: {
      type: Boolean,
      default: true,
    },
    /** modal最外层class */
    modalClass: String,
    /**
     * @title 组件高度
     * @type select
     * @description 当前页面组件高度，默认整个页面高度
     * @groupName ['公共设置']
     * @options [{label:'沾满屏幕',value:'100vh'},{label:'实际高度',value:'auto'}]
     */
    pageHeight: String,
    /**
     * @title 应用名称
     * @type select
     * @description 给那个APP使用（比心，鱼耳，小星球，糖果）
     * @groupName ['公共设置']
     * @options [{label:'比心',value:'bixin'},{label:'鱼耳',value:'yuer'},{label:'小星球',value:'xxq'},{label:'糖果',value:'tangguo'}, {label:'初刻',value:'chuke'},{label:'MVP',value:'mvp'} ]
     */
    appName: {
      type: String,
      default: 'yuer',
    },
    /**
     * @title 功能选择
     * @type select
     * @description 当前页面可以支持的功能，默认登录和注册
     * @groupName ['公共设置']
     * @options [{label:'登录和注册',value:'both'},{label:'仅登录',value:'onlyLogin'},{label:'登录默认注册',value:'loginRegister'}]
     */
    applicationType: {
      type: String,
      default: APPLICATION_TYPE_ENUM.both,
    },
    /**
     * @title 活动风控标识
     * @type input
     * @description 活动风控标识riskScenes，具体值由风控提供
     * @groupName ['公共设置']
     */
    riskScenes: {
      type: String,
      default: 'ACTIVITY_WANGYU_MANEGHE',
    },
  },
  data() {
    return {
      visible: true,
      sm,
      callback: null,
    }
  },
  watch: {
    appName() {
      this.initShuMei()
      this.setXUA()
    },
    riskScenes() {
      this.initShuMei()
    },
    applicationType() {
      this.initShuMei()
    },
  },
  methods: {
    /**
     * 埋点上报
     * @param {*} loginType 0=注册 1=登录
     */
    track(loginType, uid) {
      YPP.trackVolc('config', {
        user_unique_id: uid,
      })
      YPP.track('ElementId-G289GGDH', {
        itemId: window.activityCode || '',
        loginType,
        uid,
      })
    },
    async handleLogin({
      passNumber,
      phoneNumber,
      codeNumber,
      nationCode,
      cb = _ => _,
    }) {
      if (this.isPC) {
        if (passNumber) {
          //密码登录
          await this.passwordLogin(phoneNumber, passNumber, cb)
        } else {
          //验证码登录
          await this.codeLogin(phoneNumber, codeNumber, nationCode, cb)
        }
      } else {
        if (this.applicationType === APPLICATION_TYPE_ENUM.onlyLogin) {
          //只登录
          await this.codeLogin(phoneNumber, codeNumber, nationCode, cb)
        }
        if (this.applicationType === APPLICATION_TYPE_ENUM.both) {
          //登录和注册
          await this.loginAndRegister(phoneNumber, codeNumber, false, cb)
        }
        if (this.applicationType === APPLICATION_TYPE_ENUM.loginRegister) {
          //登录默认注册
          await this.loginAndRegister(phoneNumber, codeNumber, true, cb)
        }
      }
    },
    //密码登录
    async passwordLogin(phoneNumber, passwordNumber, cb) {
      try {
        const res = await this.sm.passwordLogin(phoneNumber, passwordNumber)
        if (!res) return
        this.callback && this.callback('confirm', this)
        this.successToast && Toast.show('登录成功')
        this.track(1, res.uid || res.uidStr)
        setCookiesAndStorage(
          OUT_SIDE_CURRENT_TOKEN,
          res.accessToken,
          res.accessToken
        )
        setCookiesAndStorage(TRIGGER_SM_LOGIN, true, res.accessToken)
        rainbowReport({ eventType: 'logIn', uid: res.uid })
        this.handleClose()
      } catch (error) {
        console.log('error', error)
      } finally {
        cb()
      }
    },
    //验证码登录
    async codeLogin(phoneNumber, codeNumber, nationCode, cb) {
      try {
        const res = await this.sm.login(phoneNumber, codeNumber)
        if (!res) return
        this.callback && this.callback('confirm', this)
        this.successToast && Toast.show('登录成功')
        this.track(1, res.uid || res.uidStr)
        setCookiesAndStorage(
          OUT_SIDE_CURRENT_TOKEN,
          res.accessToken,
          res.accessToken
        )
        setCookiesAndStorage(TRIGGER_SM_LOGIN, true, res.accessToken)
        rainbowReport({ eventType: 'logIn', uid: res.uid })
        this.handleClose()
      } catch (e) {
        Toast.show(codeErrMsg)
      } finally {
        cb()
      }
    },
    //登录注册
    async loginAndRegister(phoneNumber, codeNumber, autoRegister, cb) {
      try {
        const res = await this.sm.loginAndRegister(
          phoneNumber,
          codeNumber,
          autoRegister
        )
        console.log(res)
        if (!res) return
        setCookiesAndStorage(
          OUT_SIDE_CURRENT_TOKEN,
          res.accessToken,
          res.accessToken
        )
        if (autoRegister) {
          setCookiesAndStorage(TRIGGER_SM_LOGIN, true, true)
          this.callback && this.callback('confirm', this)
          this.successToast && Toast.show('登录成功')
          this.track(res.userInfoAbsence ? 0 : 1, res.uid || res.uidStr)
          if (res.userInfoAbsence) {
            setCookiesAndStorage(TRIGGER_SM_REGISTER, true, true)
          }
          rainbowReport({ eventType: 'activity_register', uid: res.uid })
          this.handleClose()
        } else {
          if (res.userInfoAbsence) {
            // 校验成功，需完善信息
            this.$refs.loginMobile.step = STEP_ENUM.register
          } else {
            this.callback && this.callback('confirm', this)
            this.successToast && Toast.show('登录成功')
            this.track(1, res.uid || res.uidStr)
            setCookiesAndStorage(TRIGGER_SM_LOGIN, true, true)
            rainbowReport({ eventType: 'logIn', uid: res.uid })
            this.handleClose()
          }
        }
        return res
      } catch (err) {
        Toast.show(err.msg || codeErrMsg)
      } finally {
        cb()
      }
    },
    handleClose() {
      this.autoClose && this.close && this.close()
    },
    initShuMei() {
      const { appName, riskScenes, applicationType } = this
      appName && sm.init(appName || 'yuer')
      riskScenes &&
        sm.setReqHeader({
          'risk-scenes': riskScenes,
        })
      if (this.isPC || applicationType === APPLICATION_TYPE_ENUM.onlyLogin) {
        sm.setLoginType('out_site_login')
      } else {
        sm.setLoginType('out_site_register_login')
      }
    },
    setXUD(xud) {
      setCookiesAndStorage(OUT_SIDE_CURRENT_UDID, xud, xud)
    },
    setXUA() {
      const XUA = sm.getXUA()
      setCookiesAndStorage(OUT_SIDE_CURRENT_XUA, XUA, XUA)
      sm.getXUD(this.setXUD)
    },
    updateToast() {
      const keys = ['info', 'error', 'warning']
      const toast = {}
      keys.forEach(key => {
        toast[key] = msg => Toast.show(msg)
      })
      dialog.update('toast', toast)
    },
  },
  computed: {
    appClass() {
      return [
        this.isPC && this.showMask && this.lockScroll
          ? 'out-login-modal-mask'
          : '',
      ]
    },
  },
  async created() {
    this.initShuMei()
    this.updateToast()
    this.setXUA()
    // const res = await sm.login('13400000000','6666')
    // console.log('res',res)
  },
}
</script>
